import React from 'react';
import { Modal } from "react-bootstrap";


const ModalComponent = ({ children, show, setShow, dialogClassName }) => {

  return (
    <>
      <Modal
        show={show}
        onHide={() => { setShow(false) }}
        dialogClassName={dialogClassName}
        aria-labelledby="custom-modal-styling-title"
        centered
      >
        {children}
      </Modal>
    </>
  );
}

export default ModalComponent;