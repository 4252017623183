import React, { useReducer } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Constants from '../../constants/api';
import { Modal } from 'react-bootstrap';
import ModalComponent from './modal';
import styled from 'styled-components';

export const ModalBackgroundWrapper = styled.div`
	background-image: url(${(props) => props.bg});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: 0px 32px 102px rgba(23, 9, 40, 0.36);
	border-radius: 16px;
`;

const Container = styled.div`
	@media screen and (min-width: 1600px) {
		padding: 0rem 4rem 4rem 4rem;
	}
`;

const reducer = (state, action) => {
	switch (action.type) {
		case 'field': {
			return {
				...state,
				[action.field]: action.value
			};
		}
		case 'success': {
			return {
				...state,
				success: true
			};
		}
		case 'valid_fields': {
			return {
				...state,
				nameIsValid: action.nameIsValid,
				emailIsValid: action.emailIsValid,
				messageIsValid: action.messageIsValid,
				telephoneIsValid: action.telephoneIsValid,
				category: action.category
			};
		}
		case 'failure': {
			return {
				...state,
				failure: true
			};
		}
		default: {
			return state;
		}
	}
};

const initialState = {
	name: '',
	email: '',
	category: '',
	message: '',
	telephone: '',
	nameIsValid: true,
	emailIsValid: true,
	messageIsValid: true,
	telephoneIsValid: true,
	success: false,
	failure: false
};

const selectOptions = [
	'Информация относно услуги',
	'Въпрос относно цени и плащания',
	'Въпрос относно покритие',
	'Технически въпрос',
	'Друго'
];

export default ({ show, setShow, isBusiness }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const {
		name,
		email,
		category,
		message,
		telephone,
		nameIsValid,
		emailIsValid,
		messageIsValid,
		telephoneIsValid,
		success,
		failure
	} = state;

	const data = useStaticQuery(graphql`
		query PopupForm {
			bg: file(relativePath: { eq: "home/form-background.png" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						src
					}
				}
			}
		}
	`);

	const onSubmit = (e) => {
		e.preventDefault();

		if (checkFields()) {
			let send_to =
				category === '3'
					? 'support@netsurf.bg'
					: isBusiness
					? 'business_sales@netsurf.bg'
					: 'callcenter@netsurf.bg';
			const subject = selectOptions[parseInt(category)] + ' от ' + name;
			const body =
				message +
				'<br>Име: ' +
				name +
				'<br>Мейл: ' +
				email +
				'<br>Телефон: ' +
				telephone;
			const reply_to = email;

			const emailObj = {
				send_to: send_to,
				subject: subject,
				body: body,
				reply_to: reply_to
			};

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const POSToptions = {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(emailObj)
			};

			const request = new Request(Constants.MAIL_URL, POSToptions);
			fetch(request)
				.then((res) => {
					if (res.status === 200) {
						dispatch({ type: 'success' });
					} else {
						dispatch({ type: 'failure' });
					}
				})
				.catch((e) => {
					dispatch({ type: 'failure' });
				});
		}
	};

	const checkFields = () => {
		const n = validateField('name', name);
		const e = validateField('email', email);
		const m = validateField('message', message);
		const t = validateField('telephone', telephone);
		const c = category === '' ? 'default' : category;
		dispatch({
			type: 'valid_fields',
			nameIsValid: n,
			emailIsValid: e,
			messageIsValid: m,
			telephoneIsValid: t,
			category: c
		});

		return n && e && t && m;
	};

	return (
		<>
			<ModalComponent show={show} setShow={setShow} dialogClassName="form-dialog">
				<ModalBackgroundWrapper bg={data.bg.childImageSharp.fluid.src}>
					<Modal.Header>
						<Modal.Title id="custom-modal-styling-title" className="w-100">
							<div className="row w-100 d-flex justify-content-end">
								<span
									className="icon popup-close"
									onClick={() => {
										setShow(false);
									}}
								></span>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container className="container">
							{success ? (
								<>
									<h2>Успешно се свързахте с нас!</h2>
									<p>Очаквайте отговор в най-скоро време.</p>
									<button
										className="btn btn-primary green"
										type="button"
										onClick={() => setShow(false)}
									>
										Затвори
									</button>
								</>
							) : (
								<>
									<p>Не се колебай</p>
									<h2 className="mb-4">Свържи се с нас</h2>

									<form className="popup-form" onSubmit={onSubmit}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label htmlFor="name">Вашите имена</label>
												<input
													type="text"
													className="form-control"
													id="name"
													placeholder="Въведете име и фамилия"
													value={name}
													onFocus={(e) =>
														(e.target.placeholder = '')
													}
													onBlur={(e) =>
														(e.target.placeholder =
															'Въведете име и фамилия')
													}
													onChange={(v) =>
														dispatch({
															type: 'field',
															field: 'name',
															fieldValid: 'nameIsValid',
															value: v.currentTarget.value
														})
													}
												/>
												{!nameIsValid && (
													<small className="text-danger">
														Минимум 5 символа
													</small>
												)}
											</div>
											<div className="form-group col-md-6">
												<label htmlFor="category">
													Категория на запитването
												</label>
												<select
													id="category"
													className="form-control"
													onChange={(v) =>
														dispatch({
															type: 'field',
															field: 'category',
															fieldValid: '',
															value: v.currentTarget.value
														})
													}
												>
													<option selected value="default">
														Моля, изберете
													</option>
													<option value={0}>
														Информация относно услуги
													</option>
													<option value={1}>
														Въпрос относно цени и плащания
													</option>
													<option value={2}>
														Въпрос относно покритие
													</option>
													<option value={3}>
														Технически въпрос
													</option>
													<option value={4}>Друго</option>
												</select>
												{category === 'default' && (
													<small className="text-danger">
														Изберете от листа с опции
													</small>
												)}
											</div>
											<div className="form-group col-md-6">
												<label htmlFor="email">
													Вашият имейл
												</label>
												<input
													type="text"
													className="form-control"
													id="email"
													placeholder="Въведете имейл"
													value={email}
													onFocus={(e) =>
														(e.target.placeholder = '')
													}
													onBlur={(e) =>
														(e.target.placeholder =
															'Въведете имейл')
													}
													onChange={(v) =>
														dispatch({
															type: 'field',
															field: 'email',
															fieldValid: 'emailIsValid',
															value: v.currentTarget.value
														})
													}
												/>
												{!emailIsValid && (
													<small className="text-danger">
														Невалиден мейл
													</small>
												)}
											</div>
											<div className="form-group col-md-6">
												<label htmlFor="telephone">Телефон</label>
												<input
													type="text"
													className="form-control"
													id="telephone"
													placeholder="Въведете телефон"
													value={telephone}
													onFocus={(e) =>
														(e.target.placeholder = '')
													}
													onBlur={(e) =>
														(e.target.placeholder =
															'Въведете телефон')
													}
													onChange={(v) =>
														dispatch({
															type: 'field',
															field: 'telephone',
															fieldValid:
																'telephoneIsValid',
															value: v.currentTarget.value
														})
													}
												/>
												{!telephoneIsValid && (
													<small className="text-danger">
														Невалиден телефон
													</small>
												)}
											</div>
											<div className="form-group col-12">
												<label htmlFor="message">
													Вашето съобщение
												</label>
												<textarea
													className="form-control"
													id="message"
													rows="3"
													placeholder="Напишете съобщението си тук"
													onFocus={(e) =>
														(e.target.placeholder = '')
													}
													onBlur={(e) =>
														(e.target.placeholder =
															'Напишете съобщението си тук')
													}
													value={message}
													onChange={(v) =>
														dispatch({
															type: 'field',
															field: 'message',
															fieldValid: 'messageIsValid',
															value: v.currentTarget.value
														})
													}
												/>
												{!messageIsValid && (
													<small className="text-danger">
														Съобщението е задължително
													</small>
												)}
											</div>
										</div>
										<div className="w-100 text-center text-sm-right">
											<button
												type="submit"
												className={'btn btn-primary green'}
											>
												Изпрати
											</button>
										</div>
										{failure && (
											<p className="text-danger">
												Грешка при изпращането! Опитай пак.
											</p>
										)}
									</form>
								</>
							)}
						</Container>
					</Modal.Body>
				</ModalBackgroundWrapper>
			</ModalComponent>
		</>
	);
};

export const validateField = (field, value) => {
	switch (field) {
		case 'name':
		case 'firstName':
		case 'secondName':
		case 'city':
		case 'address':
			return value.length >= 3;
		case 'message':
			return value.length >= 1;
		case 'date':
			return value !== null;
		case 'telephone':
			return isValidTelephone(value);
		case 'email':
			return isValidMail(value);
		case 'file':
			return value != null;
		default:
			return false;
	}
};

const isValidTelephone = (phone) => {
	const r = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
	return r.test(phone);
};

const isValidMail = (email) => {
	const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return r.test(String(email).toLowerCase());
};

